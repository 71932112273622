import { Action, createReducer, on } from '@ngrx/store';
import { SetBasketBetData, SetBasketBetItem, SetAppLoopData, SetQuickAmount, SetRefreshOrder } from '../actions/betting.actions';


export interface BettingState {
    basketBetData: string;
    basketBetItem: { rowIndex: number, betItem: string };
    loopData: string;
    quickAmount: number;
    refreshOrder: boolean;
}

export const initialBettingState: BettingState = {
    basketBetData: '',
    loopData: '',
    quickAmount: 500,
    basketBetItem: { rowIndex: -1, betItem: '' },
    refreshOrder: false
}

const _bettingReducer = createReducer(initialBettingState,
    on(SetBasketBetData, (state, { basketBetData }) => ({ ...state, basketBetData })),
    on(SetBasketBetItem, (state, { basketBetItem }) => ({ ...state, basketBetItem })),
    on(SetAppLoopData, (state, { loopData }) => ({ ...state, loopData })),
    on(SetQuickAmount, (state, { quickAmount }) => ({ ...state, quickAmount })),
    on(SetRefreshOrder, (state, { refreshOrder }) => ({ ...state, refreshOrder })),
);

export function bettingReducer(state: BettingState, action: Action) {
    return _bettingReducer(state, action);
};
