import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { themeReducer } from './reduces/theme.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { brickReducer } from './reduces/brick.reducer';
import { bettingReducer } from './reduces/betting.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({ theme: themeReducer, brick: brickReducer, betting: bettingReducer }, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true
      }
    }),

    StoreDevtoolsModule.instrument({
      maxAge: 20,
      logOnly: environment.production
    })
  ]
})
export class AppStoreModule { }
