import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzCarouselModule, NzLayoutModule, NzButtonModule, NzProgressModule, NzGridModule, NzDatePickerModule, NzTableModule, NzSelectModule, NzModalModule, NzBreadCrumbModule, NzCheckboxModule, NzSliderModule, NzFormModule, NzIconModule, NzPopoverModule, NzBadgeModule, NzListModule, NzDropDownModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { MarqueeDirective } from './directive/marquee.directive';
import { SubDigetPipe } from './pipe/subDiget.pipe';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { TrustHtmlPipe } from './pipe/trustHtml.pipe';
import { RouterModule } from '@angular/router';
import { XbUiModule } from './xb-ui/xb-ui.module';
import { PipeModule } from './pipe.module';
import { IconDefinition } from '@ant-design/icons-angular';
import {
    SearchOutline, FileImageOutline, SmileOutline, PlusCircleOutline, MinusCircleOutline, SettingFill,
    CloseCircleOutline
} from '@ant-design/icons-angular/icons';


const icons: IconDefinition[] = [SearchOutline, FileImageOutline,
    SmileOutline, PlusCircleOutline, SettingFill, MinusCircleOutline, CloseCircleOutline];

@NgModule({
    declarations: [
        MarqueeDirective,
        SafeUrlPipe,
        TrustHtmlPipe,
    ],
    imports: [
        CommonModule,
        NzCarouselModule,
        NzGridModule,
        NzLayoutModule,
        NzButtonModule,
        NzProgressModule,
        FormsModule,
        NzDatePickerModule,
        NzTableModule,
        NzSelectModule,
        NzModalModule,
        NzBreadCrumbModule,
        RouterModule,
        NzInputModule,
        NzCheckboxModule,
        NzSliderModule,
        NzFormModule,
        ReactiveFormsModule,
        NzBadgeModule,
        XbUiModule,
        PipeModule,
        NzDropDownModule,
        NzPopoverModule,
        NzListModule,
        NzSpinModule,
        NzPaginationModule,
        NzInputModule,
        NzStatisticModule,
        NzIconModule.forRoot(icons),

    ],
    exports: [
        CommonModule,
        NzCarouselModule,
        NzGridModule,
        NzLayoutModule,
        NzButtonModule,
        NzProgressModule,
        XbUiModule,
        MarqueeDirective,
        SubDigetPipe,
        SafeUrlPipe,
        TrustHtmlPipe,
        FormsModule,
        MarqueeDirective,
        NzDatePickerModule,
        NzTableModule,
        NzSelectModule,
        NzDropDownModule,
        NzModalModule,
        NzBreadCrumbModule,
        NzListModule,
        RouterModule,
        NzInputModule,
        NzCheckboxModule,
        NzSliderModule,
        NzFormModule,
        NzIconModule,
        ReactiveFormsModule,
        XbUiModule,
        PipeModule,
        NzPopoverModule,
        NzBadgeModule,
        NzSpinModule,
        NzStatisticModule,
        NzInputModule,
        NzPaginationModule,
    ]
})
export class ShareModule { }
