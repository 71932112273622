import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule, NzButtonModule, NzLayoutModule, NzGridModule, NzCheckboxModule, NzSliderModule, NzIconModule, NzDatePickerModule, NzTableModule, NzSelectModule, NzModalModule, NzBreadCrumbModule, NzBadgeModule, NzPaginationModule, NzSpinModule, NzDropDownModule, NzToolTipModule } from 'ng-zorro-antd';
import { XbLoginFormComponent } from './xb-login-form/xb-login-form.component';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzInputModule } from 'ng-zorro-antd/input';
import { SliderVerificationComponent } from './slider-verification/slider-verification.component';
import { TodayAwardComponent } from './today-award/today-award.component';
import { RecommendBrickComponent } from './recommend-brick/recommend-brick.component';
import { HotBetComponent } from './hot-bet/hot-bet.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { BillRecordComponent } from './bill-record/bill-record.component';
import { PipeModule } from '../pipe.module';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { OrderDetialComponent } from './order-detial/order-detial.component';
import { RevocationDircetive } from '../directive/revocation.directive';
import { LotteryReportComponent } from './lottery-report/lottery-report.component';
import { GameRecordComponent } from './game-record/game-record.component';
import { ThirdgameReportComponent } from './thirdgame-report/thirdgame-report.component';
import { ThirdgameRecordComponent } from './thirdgame-record/thirdgame-record.component';
import { ChaseRecordTableComponent } from './chase-record-table/chase-record-table.component';
import { XbtabComponent } from './xbtab/xbtab.component';
import { ChatRoomComponent } from './chat-room/chat-room.component';
import { ContactComponent } from './chat-room/contact/contact.component';
import { GroupComponent } from './chat-room/group/group.component';
import { ChattingComponent } from './chat-room/chatting/chatting.component';
import { ChattingListComponent } from './chat-room/chatting-list/chatting-list.component';
import { FormatTimePipe } from '../pipe/format-time.pipe';
import { DailySettingComponent } from 'src/app/pages/personal/agent/account-center/daily-setting/daily-setting.component';
import { CopyDircetive } from '../directive/copy.directive';
@NgModule({
  declarations: [
    XbLoginFormComponent,
    SliderVerificationComponent,
    TodayAwardComponent,
    RecommendBrickComponent,
    HotBetComponent,
    HeaderComponent,
    FooterComponent,
    BillRecordComponent,
    OrderDetialComponent,
    RevocationDircetive,
    CopyDircetive,
    LotteryReportComponent,
    GameRecordComponent,
    ThirdgameReportComponent,
    ThirdgameRecordComponent,
    ChaseRecordTableComponent,
    XbtabComponent,
    ChatRoomComponent,
    ContactComponent,
    GroupComponent,
    ChattingComponent,
    ChattingListComponent,
    FormatTimePipe,
    DailySettingComponent,

  ],
  imports: [
    CommonModule,
    RouterModule,
    NzGridModule,
    NzLayoutModule,
    NzInputModule,
    NzButtonModule,
    NzCheckboxModule,
    NzSliderModule,
    NzFormModule,
    FormsModule,
    NzIconModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    NzTableModule,
    NzSelectModule,
    NzModalModule,
    PipeModule,
    NzPopoverModule,
    NzBreadCrumbModule,
    NzBadgeModule,
    NzPaginationModule,
    NzDropDownModule,
    NzInputNumberModule,
    NzSpinModule,
    NzToolTipModule
  ],
  exports: [
    XbLoginFormComponent,
    SliderVerificationComponent,
    TodayAwardComponent,
    RecommendBrickComponent,
    HotBetComponent,
    HeaderComponent,
    FooterComponent,
    BillRecordComponent,
    OrderDetialComponent,
    RevocationDircetive,
    CopyDircetive,
    LotteryReportComponent,
    GameRecordComponent,
    ThirdgameReportComponent,
    ThirdgameRecordComponent,
    ChaseRecordTableComponent,
    XbtabComponent,
    ChatRoomComponent,
    FormatTimePipe,
    DailySettingComponent,
  ]
})
export class XbUiModule { }
