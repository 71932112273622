import { Injectable } from '@angular/core';
@Injectable()
export class RongImService {
    constructor() {

    }
    /**
     * 注册实例化RongYunIm服务
     * evan 2018-06-29
     * @memberof RongImService
     */
    init() {
        RongIMLib.RongIMEmoji.init();
        // RongIMLib.RongIMVoice.init();
    }


    // 获取表情库列表
    getEmoji() {
        return RongIMLib.RongIMEmoji.list.map(function (data) {
            return data.node;
        });
    }
}
