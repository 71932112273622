import { CurrentUserStateModel } from '../model/system.model';
import { BrickTypesModel } from '../model/lottery.model';
import { UserModel, VoiceStatusModel, BingoAlertModel } from '../model/user.model';
import { InitPageModel, LoopDataModel } from '../model/webajax.model';

export class AppConsts {
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static serviceUrl: string;
    static localeMappings: any = [];
    static remoteApiBaseUrl: string;
    static rmeoteSignalrBaseUrl: string;
    static crowdingOut: number = 0;
    static breakOut: number = 0
    /**
     * 登录成功后 初始化数据 用户信息、彩票配置信息等
     */
    static initPageData: InitPageModel;
    static hasSystemNotice = false;
    /**
     *六合彩年份
     */
    public static lhcYear: number = 0;
    /**
     * 定时任务 执行 loop API获取用户余额相关信息
     */
    static loopTimerObj = {};
    /** 定时器ID */
    static loopTimerId = 1;
    /** loop 需要的 参数 */
    static loopParams = [];
    /** loop 回调函数 */
    static loopCallBack = [];
    /** 轮训实体对象信息  */
    static loopDataModel: LoopDataModel;
    /**
     * 默认初始化彩种编码
     */
    static defaultBrickCode = '';

    /**
     * 定时任务 获取系统消息
     */
    static loopSystemMessageTimerObj = {};
    /** 轮询获取系统消息 */
    static loopSystemMessageTimerId = 1;

    /**
     * 音效配置
     */
    static waitingVoice: VoiceStatusModel;
    static prizeVoice: VoiceStatusModel;
    static bingoAlert: BingoAlertModel;
    /**
     * 彩种类型 配置
     */
    static brickTypes: BrickTypesModel[] = [];
    /**
     * 个人中心 用户绑定状态
     */
    static personalStatus: CurrentUserStateModel = new CurrentUserStateModel();
    /**
     * 银行卡号码验证
     * @param e 验证输入的银行卡号
     */
    static checkCardId(e) {
        var x = e.substr(e.length - 1, 1);
        var b = e.substr(0, e.length - 1);
        var A = new Array();
        for (var w = b.length - 1; w > -1; w--) {
            A.push(b.substr(w, 1));
        }
        var s = new Array();
        var a = new Array();
        var g = new Array();
        for (var v = 0; v < A.length; v++) {
            if ((v + 1) % 2 == 1) {
                if (parseInt(A[v]) * 2 < 9) {
                    s.push(parseInt(A[v]) * 2);
                } else {
                    a.push(parseInt(A[v]) * 2);
                }
            } else {
                g.push(A[v]);
            }
        }
        var d = new Array();
        var c = new Array();
        for (var y = 0; y < a.length; y++) {
            d.push(parseInt(a[y]) % 10);
            c.push(parseInt(a[y]) / 10);
        }
        var z = 0;
        var u = 0;
        var l = 0;
        var f = 0;
        var C = 0;
        for (var r = 0; r < s.length; r++) {
            z = z + parseInt(s[r]);
        }
        for (var q = 0; q < g.length; q++) {
            u = u + parseInt(g[q]);
        }
        for (var o = 0; o < d.length; o++) {
            l = l + parseInt(d[o]);
            f = f + parseInt(c[o]);
        }
        C = parseInt(z + '') + parseInt(u + '') + parseInt(l + '') + parseInt(f + '');
        var t = parseInt(C + '') % 10 == 0 ? 10 : parseInt(C + '') % 10;
        var B = 10 - t;
        if (x == B) {
            return true;
        } else {
            return false;
        }
    }


    /**
     * 当前登录用户账号信息 用户投注配置信息
     */
    static userInfo: UserModel = new UserModel();


    /**
     * 第三方游戏平台
     */
    static thirdGamePlatform: any = [];

    static audioElement = new Audio();

    static voicePlay(item: VoiceStatusModel, callBack?: () => void) {
        const audioUrl = '../assets/audio/';
        switch (item.type) {
            case 'lottery': //开奖提示音
                this.audioElement = new Audio(audioUrl + 'number/open.wav');
                break;
            case 'prize': //中奖提示音
                this.audioElement = new Audio(audioUrl + 'prize.mp3');
                break;
            case 'message': //消息提示音
                this.audioElement = new Audio(audioUrl + 'message.mp3');
                break;
            case 'waiting': //封单提示音
                // this.audioElement = new Audio(audioUrl + 'sealing.mp3');
                this.audioElement = new Audio(audioUrl + 'countDownBeep.mp3');
                break;
        }
        if (item.playStatus) {
            this.audioElement.muted = false;
            !this.audioElement.paused && (this.audioElement.pause(), (this.audioElement.currentTime = 0));
            this.audioElement.play();
            this.audioElement.onended = () => {
                item.playStatus = !item.playStatus;
                if (callBack)
                    callBack();
            };
        } else {
            this.audioElement.remove();
        }
    }
    static voiceStop() {
        this.audioElement.pause();
        this.audioElement.currentTime = 0;
    }

}