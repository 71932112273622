import { Injectable } from "@angular/core";
import * as NP from 'number-precision';

@Injectable({providedIn:'root'})
export class NpCalculatorService {
    /** 加法 */
    plus(a, b) {
        return NP.plus(a, b);
    }
    /** 除法 */
    divide(a, b) {
        return NP.divide(a, b);
    }
    /** 减法 */
    minus(a, b) {
        return NP.minus(a, b);
    }
    /** 乘法 */
    times(a, b) {
        return NP.times(a, b);
    }
}