import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ImCommunicationService{
    public msgSource = new BehaviorSubject<string>('Start');
    send(message:string):void{
        this.msgSource.next(message);
    }

    // public connectFlagSource = new BehaviorSubject<any>('Start');
    // catchflag(flag:any):void{
    //     this.connectFlagSource.next(flag);
    // }
}