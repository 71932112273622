import { createAction, props } from '@ngrx/store';

/** 设置 购彩篮信息 */
export const SetBasketBetData = createAction("[betting] set basket bet data ", props<{ basketBetData: string }>());

/** 设置 购彩篮中需要删除的某一项  */
export const SetBasketBetItem = createAction("[betting] set basket bet item will delete", props<{ basketBetItem: { rowIndex: number, betItem: string } }>());

/** 设置 购彩篮中需要删除的某一项  */
export const SetAppLoopData = createAction("[system] set app loop data ", props<{ loopData: string }>());

/** 信用玩法设置快捷金额  */
export const SetQuickAmount = createAction("[betting] set quick amount", props<{ quickAmount: number }>());

/** 刷新订单状态  */
export const SetRefreshOrder = createAction("[betting] set order status refresh", props<{ refreshOrder: boolean }>());