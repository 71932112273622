export class AccountModel {
    constructor() {
        this.username = null;
        this.nickname = null;
        this.type = null;
        this.registTime = null;
        this.loginTime = null;
        this.lockTime = null;
        this.status = null;
        this.onlineStatus = null;
        this.bindStatus = null;
        this.typeCode = null;
        this.allowTransfer = null;
        this.googleBind = null;
        this.hasWithDrawPassWord = null;
        this.vipCode = null;
        this.adjustType = null;
        this.allowRecharge = null;
    }
    // accountLoginLog: AccountLoginLogModel;
    username: string;
    nickname: string;
    type: number;
    registTime: number;
    loginTime: number;
    lockTime: object;
    status: number;
    onlineStatus: number;
    bindStatus: number;
    typeCode: string;
    allowTransfer: boolean;
    googleBind: boolean;
    hasWithDrawPassWord: boolean;
    vipCode: object;
    adjustType: number;
    allowRecharge: boolean;
    allowWithdraw: boolean;
}

export class AccountBrickConfigModel {
    constructor() {
        this.allowEqualCode = null;
        this.availableBalance = null;
        this.blockedBalance = null;
        this.code;
        this.codeType = null;
        this.extraPoint = null;
        this.isContractDividendAccount = null;
        this.isDividendAccount = null;
        this.playStatus = null;
        this.point;
        this.salaryRebate = null;
        this.isDayDividendAccount = null;
        this.isDaySalaryAccount = null;
        this.lotteryPrivateSwitch = null;
    }
    /**
     * 是否允许登录奖级
     */
    allowEqualCode: boolean;
    /**
     * 可用余额
     */
    availableBalance: number;
    /**
     * 限制余额
     */
    blockedBalance: number;
    /**
     * :奖级
     */
    code: number;
    /**
     * 	奖级列表
     */
    codeType: string;
    /**
     * 	额外返点
     */
    extraPoint: string;
    /**
     * 无
     */
    isContractDividendAccount: boolean;
    /**
     * 	是否是分红账户
     */
    isDividendAccount: boolean;
    /**
     * true：可以玩游戏
     */
    playStatus: number;
    /**
     * 返点
     */
    point: string;
    /**
     * 工资返点
     */
    salaryRebate: string;
    /** 日分红 */
    isDayDividendAccount: boolean;
    /** 日工资 */
    isDaySalaryAccount: boolean;
    lotteryPrivateSwitch: boolean;
}

export class UserModel {
    constructor() {
        this.isLogin = null;
        this.account = new AccountModel();
        this.brickConfig = new AccountBrickConfigModel();
        this.msgCount = null;
        this.isDayDividendAccount = null;
        this.isDaySalaryAccount = null;
        this.main = new AccountModel();
        this.lottery = new AccountBrickConfigModel();
        this.lotteryPrivateSwitch = null;
        this.platformType = null;
    }
    isLogin: boolean;
    account: AccountModel;
    brickConfig: AccountBrickConfigModel;
    msgCount: number;
    isDayDividendAccount: boolean;
    isDaySalaryAccount: boolean;
    main: AccountModel;
    lottery: AccountBrickConfigModel;
    lotteryPrivateSwitch: boolean;
    platformType: string;
}

/**
 * 用户系统声音设置
 */
export class VoiceStatusModel {
    constructor() {
        this.name = null;
        this.playStatus = false;
        this.isPlaying = false;
        this.type = null;
    }
    name: string;
    playStatus: boolean;
    isPlaying: boolean;
    type: string;
}

/** 用户中奖弹框提示 */
export class BingoAlertModel {
    constructor() {
        this.name = null;
        this.isOpen = true;
    }
    name: string;
    isOpen: boolean;
}
export class PersonalCenterStatus {
    constructor() {
        // this.isBindBirthday = false;
        // this.isBindCard = false;
        // this.isBindCellphone = false;
        // this.isBindEmail = false;
        // this.isBindGoogleAuthenticator = false;
        // this.isBindQQ = false;
        // this.isBindSecurity = false;
        // this.isBindWithdrawName = false;
        // this.isBindWithdrawPassword = false;
        this.isBindBirthday = null;
        this.isBindCard = null;
        this.isBindCellphone = null;
        this.isBindEmail = null;
        this.isBindGoogleAuthenticator = null;
        this.isBindQQ = null;
        this.isBindSecurity = null;
        this.isBindWithdrawName = null;
        this.isBindWithdrawPassword = null;
    }

    /**
     * 是否绑定生日信息
     */
    isBindBirthday: boolean;
    /**
     * 是否绑定银行卡
     */
    isBindCard: boolean;
    /**
     * 是否绑定电话号码
     */
    isBindCellphone: boolean;
    /**
     * 是否绑定Eamil
     */
    isBindEmail: boolean;
    /**
     * 是否绑定google验证
     */
    isBindGoogleAuthenticator: boolean;
    /**
     * 是否绑定QQ
     */
    isBindQQ: boolean;
    /**
     * 是否绑定密保信息
     */
    isBindSecurity: boolean;
    /**
     * 是否绑定取款名称
     */
    isBindWithdrawName: boolean;
    /**
     * 是否绑定资金密码
     */
    isBindWithdrawPassword: boolean;
}

export class ReportGameLotteryModel {
    constructor() {
        this.activity = null;
        this.bonus = null;
        this.consume = null;
        this.dividend = null;
        this.field = null;
        this.profit = null;
        this.rebateAgent = null;
        this.rebateConsume = null;
        this.recharge = null;
        this.withdraw = null;
        this.validConsume = null;
        this.teamSubAccountNumber = null;
        this.playerName = '';
    }
    activity: number;
    bonus: number;
    consume: number;
    dividend: number;
    field: string;
    profit: number;
    rebateAgent: number;
    rebateConsume: number;
    recharge: number;
    withdraw: number;
    teamSubAccountNumber: number;
    validConsume: number;
    playerName: string;
}

export class DiviendModel {
    constructor() {
        this.accountType = null;
        this.hasRecord = null;
        this.lastClearTime = null;
        this.limitConsume = null;
        this.limitDays = null;
        this.scale = null;
        this.thisAmount = null;
        this.thisBaseScale = null;
        this.thisEndDate = null;
        this.thisExtraScale = null;
        this.thisStartDate = null;
        this.thisTotalConsume = null;
        this.thisTotalLoss = null;
        this.totalBalance = null;
        this.totalConsume = null;
        this.totalCount = null;
        this.totalLoss = null;
        this.title = '';
    }

    accountType: string;
    /* 是否有领取分红，true时显示领取按钮*/
    hasRecord: boolean;
    /* 上次领取时间 */
    lastClearTime: any;
    limitConsume: any;
    limitDays: any;
    scale: number;
    /* 当前分红总额*/
    thisAmount: number;
    /* 分红基本比例 显示时需拼接%*/
    thisBaseScale: number;
    /* 当前分红结束时间 */
    thisEndDate: number;
    /* 分红额外奖励比例，彩票、ag、棋牌显示比例时候用thisBaseScale+thisExtraScale的值 */
    thisExtraScale: number;
    /* 当前分红开始时间 */
    thisStartDate: number;
    /* 当前团队投注量 */
    thisTotalConsume: number;
    /* 当前团队投注量 */
    thisTotalLoss: number;
    /* 已分红总计 */
    totalBalance: number;
    totalConsume: number;
    /* 已结算次数 */
    totalCount: number;
    /* 已结亏算了总计 */
    totalLoss: number;
    title?: string;
}
export interface pswParamModel {
    oldpwd: string;
    newpwd: string;
    commpwd: string;
    googleKey?: any;
}

export interface resetPswParamModel {
    newpwd: string;
    commpwd: string;
}
//修改资金密码
export class drawPasswordparamModel {
    loginPassword?: '';
    oldPassword?: '';
    password: '';
    rePassword: '';
    googleKey?: any;
}
