import { createReducer, on, Action } from '@ngrx/store'
import { setTheme } from '../actions/theme.actions';
import { ThemeState } from 'src/app/model/system.model';

/**
 * 设置MenuState初始值
 */
export const initialThemeState: ThemeState = {
    themeMode: { themeCode: 'light', themeName: '浅色' }
};

const _themeReducer = createReducer(initialThemeState,
    on(setTheme, (state, { themeMode }) => ({ ...state, themeMode }))
);

export function themeReducer(state: ThemeState, action: Action) {
    return _themeReducer(state, action);
};