import { Injectable, Inject } from '@angular/core';
import { ServicesModule, API_CONFIG } from './services.module';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginInfoModel, ResultModel } from '../model/system.model';
import * as queryString from 'querystring';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators';
import { AppConsts } from '../core/AppConsts';
import { CommonObservableService } from './common-observable.service';

@Injectable({ providedIn: ServicesModule })
export class LoginService {

    constructor(
        private comObserService: CommonObservableService,
        private http: HttpClient,
        @Inject(API_CONFIG) private uri: string
        ) { }

    /**
     * 用户登录
     */
    login(formValue: string): Observable<ResultModel<LoginInfoModel>> {
        this.clearUserInfo();
        return this.http.post(this.uri + 'login', formValue).pipe(
            map((res: ResultModel<LoginInfoModel>) => {
                return res;
            })
        )
    }

    logout(): Observable<ResultModel<any>> {
        this.comObserService.socketStatus.next(true);
        AppConsts.userInfo.isLogin = false;
        this.clearUserInfo();
        const params = queryString.stringify({});
        return this.http.post(this.uri + 'logout', params).pipe(
            map((res: ResultModel<any>) => { return res; })
        )
    }
    /**
     * 注册滑块验证
     */
    getSecurityCode(formValue): Observable<ResultModel<any>> {
        const params = new HttpParams({ fromString: queryString.stringify(formValue) })
        return this.http.post(this.uri + 'get-security-code', { params }).pipe(map((res: ResultModel<any>) => { return res }))
    }

    /** 登录时清除IM内容 */
    clearUserInfo() {
        localStorage.removeItem('socketUrl');
        localStorage.removeItem('imToken');
        localStorage.removeItem('imID');
    }
}
