import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class MyobserverService {
    openDistributeIm = new Subject<{ trsnfer: boolean, code: string, balance: number }>();
    oneRecycling = new Subject<boolean>();
    cleanBetting = new Subject<boolean>();
    /** 一键回收完成 */
    clearRecycling = new Subject<string>();
}
