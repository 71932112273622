/// <reference path="./../assets/scripts/emoji.d.ts" />
import { Component, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/internal/operators';
import { Observable } from 'rxjs/internal/Observable';
import { interval } from 'rxjs/internal/observable/interval';
import { NzConfigService } from 'ng-zorro-antd';
@Component({
  selector: 'app-root',
  template: `<nz-progress *ngIf="loadPercent < 100" class="loading-bar" nzStrokeWidth="5" [nzShowInfo]="false" nzStatus="active" [nzPercent]="loadPercent"></nz-progress>
  <router-outlet></router-outlet>`,
  styles: [
    `.loading-bar {
      position: fixed;
      width: 100%;
      top: -11px;
      z-index: 10000;
    }`
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  // 标题
  title = '宝博娱乐';

  loadPercent = 0;

  private navEnd: Observable<NavigationEnd>;

  constructor(
    private router: Router,
    private configService: NzConfigService
  ) {
    this.configService.set('message', { nzTop: 320 });
    this.router.events.pipe(filter(evt => evt instanceof NavigationStart)).subscribe(() => {
      this.loadPercent = 0;
      this.setLoadingBar();
    });

    this.navEnd = this.router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }


  private setLoadingBar() {
    interval(500).pipe(
      takeUntil(this.navEnd)
    ).subscribe(() => {
      let loadPrecent = Math.max(80, ++this.loadPercent);
      if (loadPrecent < 100) {
        this.loadPercent = loadPrecent;
      } else {
        this.loadPercent = 99.9;
      }
    });

    this.navEnd.subscribe(() => {
      this.loadPercent = 100;
      window.scrollTo(0, 0);
    });
  }
}
