import { createReducer, Action, on } from '@ngrx/store';
import { StaticOpenCodeModel } from 'src/app/model/lottery.model';
import {
  SetBrickOpenCode, SetBrickOpenTime, SetAudioPlaying, SetReloadOpenCode, SetMenuVisible,
  SetBettingData, SetBrickAdjustPrize, SetUserMethodInfo, SetCancelOrder
} from '../actions/brick.actions';

export interface BrickState {
  menuVisible: boolean;
  issueNo: string;
  playing: boolean;
  adjustPrizes: { prizes: string, adjustModelString: string },
  userMethodType: number,
  bettingData: { bettingNum: number; betContent: any }
  openCode: StaticOpenCodeModel,
  openTime: string;
  cancelOrderState: boolean;
};

export const initialBrickState: BrickState = {
  menuVisible: true,
  issueNo: null,
  adjustPrizes: { prizes: null, adjustModelString: null },
  userMethodType: 0,
  playing: false,
  bettingData: { bettingNum: 0, betContent: null },
  openCode: null,
  openTime: '',
  cancelOrderState: false,

};
const reducer = createReducer(initialBrickState,
  on(SetMenuVisible, (state, { menuVisible }) => ({ ...state, menuVisible })),
  on(SetReloadOpenCode, (state, { issueNo }) => ({ ...state, issueNo })),
  on(SetAudioPlaying, (state, { playing }) => ({ ...state, playing })),
  on(SetBrickAdjustPrize, (state, { adjustPrizes }) => ({ ...state, adjustPrizes })),
  on(SetUserMethodInfo, (state, { userMethodType }) => ({ ...state, userMethodType })),
  on(SetBettingData, (state, { bettingData }) => ({ ...state, bettingData })),
  on(SetBrickOpenCode, (state, { openCode }) => ({ ...state, openCode })),
  on(SetBrickOpenTime, (state, { openTime }) => ({ ...state, openTime })),
  on(SetCancelOrder, (state, { cancelOrderState }) => ({ ...state, cancelOrderState }))
);

export function brickReducer(state: BrickState, action: Action) {
  return reducer(state, action);
};
