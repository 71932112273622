

import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { registerLocaleData, PlatformLocation } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppConsts } from './core/AppConsts';
import { ServicesModule } from './services/services.module';
import { AppStoreModule } from './store';
import { AppRoutingModule } from './app-routing.module';
import { httpInterceptorProvides } from './services/interceptors';
import { ShareModule } from './share/share.module';
import { NzProgressModule, NzNotificationService, NzModalService, NzMessageModule, NzNotificationContainerComponent } from 'ng-zorro-antd';
import { CommonObservableService } from './services/common-observable.service';
import { AmountMode } from './model/betting.model';
import { MyobserverService } from './services/myobserver.service';
import { VoiceStatusModel } from './model/user.model';
import { ImCommunicationService } from './services/im.communication.service';
import { RongImService } from './services/ryim.service';
import { NpCalculatorService } from './services/np.calculator.service';

registerLocaleData(zh);

const _ngZorroConfig: NzConfig = {
    // 注意组件名称没有 nz 前缀
    // message: { nzTop: 120 },
    message: { nzMaxStack: 1, nzDuration: 3000, nzPauseOnHover: false },
    notification: { nzBottom: 24, nzMaxStack: 3, nzPlacement: 'bottomRight' },
};

export function appInitializerFactory(platformLocation: PlatformLocation, http: HttpClient) {
    return () => {
        AppConsts.appBaseHref = getBaseHref(platformLocation)
        const appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;
        const appRootUrl = appBaseUrl + 'assets/' + environment.appConfig;
        return http.get(appRootUrl).subscribe((config: any) => {
            AppConsts.appBaseUrl = config.appBaseUrl;
            AppConsts.remoteApiBaseUrl = config.remoteApiBaseUrl;
            AppConsts.rmeoteSignalrBaseUrl = config.rmeoteSignalrBaseUrl;
            AppConsts.brickTypes = config.brickTypes;
            AppConsts.defaultBrickCode = config.defaultBrickCode;
            AppConsts.serviceUrl = config.serviceUrl;
            AppConsts.waitingVoice = config.waitingVoice;
            AppConsts.prizeVoice = config.prizeVoice;
            AppConsts.bingoAlert = config.bingoAlert;

            const waitingObj = localStorage.getItem('VOICE_WAITING');
            const prizeObj = localStorage.getItem('VOICE_PRIZE');
            const bingoAlertObj = localStorage.getItem('BINGO_ALERT');
            setVoice(waitingObj, 'VOICE_WAITING', AppConsts.waitingVoice);
            setVoice(prizeObj, 'VOICE_PRIZE', AppConsts.prizeVoice);
            setVoice(bingoAlertObj, 'BINGO_ALERT', AppConsts.bingoAlert);
            let quickAmounts: AmountMode[] = config.quickAmounts;
            const _quickAmounts = localStorage.getItem('DEFAULT_QUICK_AMOUNTS');
            if (_quickAmounts && _quickAmounts !== "undefined") {
                quickAmounts = JSON.parse(localStorage.getItem('DEFAULT_QUICK_AMOUNTS'));
            }
            localStorage.setItem('DEFAULT_QUICK_AMOUNTS', JSON.stringify(quickAmounts));

            let longQuickAmounts: AmountMode[] = config.longQuickAmounts;
            const _longQuickAmounts = localStorage.getItem('LONG_QUICK_AMOUNTS');
            if (_longQuickAmounts && _longQuickAmounts !== "undefined") {
                longQuickAmounts = JSON.parse(localStorage.getItem('LONG_QUICK_AMOUNTS'));
            }
            localStorage.setItem('LONG_QUICK_AMOUNTS', JSON.stringify(longQuickAmounts));

        });
    }
}

export function setVoice(obj, str, original) {
    if (obj && obj !== "undefined") {
        let temp = JSON.parse(obj);
        temp.isPlaying = false;
        localStorage.setItem(str, JSON.stringify(temp));
        switch (str) {
            case 'VOICE_PRIZE':
                AppConsts.prizeVoice = obj;
                break;
            case 'VOICE_WAITING':
                AppConsts.waitingVoice = obj;
                break;
            case 'BINGO_ALERT':
                AppConsts.bingoAlert = obj;
                break;
        }
    } else {
        localStorage.setItem(str, JSON.stringify(original));
    }
}

export function getBaseHref(platformLocation: PlatformLocation): string {
    const baseUrl = platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
        return baseUrl;
    }
    return '/';
}

export function getDocumentOrigin() {
    if (!document.location.origin) {
        const port = document.location.port ? ':' + document.location.port : '';
        return document.location.protocol + '//' + document.location.hostname + port;
    }

    return document.location.origin;
}



@NgModule({
    declarations: [
        AppComponent,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NzProgressModule,
        NzMessageModule,
        AppRoutingModule,
        ServicesModule,
        AppStoreModule,
        ShareModule
    ],
    providers: [
        NzNotificationService,
        NzModalService,
        MyobserverService,
        CommonObservableService,
        ImCommunicationService,
        RongImService,
        NpCalculatorService,
        { provide: NZ_I18N, useValue: zh_CN },
        { provide: NZ_CONFIG, useValue: _ngZorroConfig },
        { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [PlatformLocation, HttpClient], multi: true },
        httpInterceptorProvides,
    ],
    exports: [
        ShareModule
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [
        NzNotificationContainerComponent
    ]
})
export class AppModule { }
