import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'domains',
    loadChildren: () => import('./pages/domains/domains.module').then(mod => mod.DomainsModule)
  },
  {
    path: 'domains/:type',
    loadChildren: () => import('./pages/domains/domains.module').then(mod => mod.DomainsModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(mod => mod.LoginModule)
  },
  // {
  //   path: ':registCode', loadChildren: () => import('./pages/register/register.module').then(mod => mod.RegisterModule)
  // },
  {
    path: 'register', loadChildren: () => import('./pages/register/register.module').then(mod => mod.RegisterModule)
  },
  {
    path: 'complete-trend/:shortName', loadChildren: () => import('./pages/brick/xb-brick-trend/complete-trend/complete-trend.module').then(mod => mod.CompleteTrendModule)
  }, 
  {
    path: 'lottery-trend/:code', loadChildren: () => import('./pages/brick/xb-brick-trend/lottery-trend/lottery-trend.module').then(mod => mod.LotteryTrendModule)
  },
  {
    path: 'app', loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule)
  },
  {
    path: '', redirectTo: '/domains', pathMatch: 'full'
  },
  { path: '**', redirectTo: '/domains' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
