import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserModel, VoiceStatusModel, PersonalCenterStatus } from '../model/user.model';
import { ChaseIssueAndCountDownModel } from '../model/lottery.model';

@Injectable()
export class CommonObservableService {

    /**
     * loop 用户基本信息和用户基本彩票配置信息
     * evan 2019-05-05
     */
    usesInfoSubject = new Subject<UserModel>();

    /**
     * 倒计时期号和时间信息
     * evan 2019-06-01
     */
    brickCountDownSubject = new Subject<ChaseIssueAndCountDownModel>();

    /**
     * 倒计时期号和时间信息
     * evan 2019-06-01
     */
    voiceSettingSubject = new Subject<VoiceStatusModel[]>();

    /**
     * 个人信息绑定状态
     * evan 2019-06-01
     */
    personalStatus = new Subject<PersonalCenterStatus>();



    imWinStatus = new Subject<boolean>();

    /** 退出登录同时退出socket连接 */
    socketStatus = new Subject<boolean>();
    
}
