import { createAction, props } from '@ngrx/store';
import { StaticOpenCodeModel } from 'src/app/model/lottery.model';

/** 设置 左侧彩种菜单隐藏、显示  */
export const SetMenuVisible = createAction("[brick] set leftMenu visible state", props<{ menuVisible: boolean }>());
/** 设置 获取开奖历史记录/指定彩种开奖信息 */
export const SetReloadOpenCode = createAction("[brick] set brick last open code issue number", props<{ issueNo: string }>());
/** 设置开奖提示音量 */
export const SetAudioPlaying = createAction("[brick] set audio playing status", props<{ playing: boolean }>());
/** 设置开奖号码 */
export const SetBrickOpenCode = createAction("[brick] set Brick OpenCode", props<{ openCode: StaticOpenCodeModel }>());
/** 设置开奖时间及期号*/
export const SetBrickOpenTime = createAction("[brick] set Brick OpenTime", props<{ openTime: string }>());
/** 设置选中的系列 时时彩，十一选五 等 */
export const SetSelectedSeries = createAction("[brick] set selected series", props<{ selectedSeries: string }>());
/** 设置选中的彩种 如 重庆时时彩，腾讯分分彩等 */
export const SetSelectedBrick = createAction("[brick] set selected brick", props<{ selectedBrick: string }>());
/** 设置信用或者官方玩法类别 */
export const SetUserMethodInfo = createAction("[brick] set user methon type", props<{ userMethodType: number }>());
// /** 设置信用或者官方玩法等 */
// export const SetOfficialPlays = createAction("[brick] Set Official Plays", props<{ officialPlayString: string }>());
// /** 设置信用或者官方玩法等 */
// export const SetCreditlPlays = createAction("[brick] Set Credit Plays", props<{ creditPlayString: string }>());
// /** 选择玩法组 大类 如 五星 、四星 、前三等 */
// export const SetSelectedMethodGroupItem = createAction("[brick] set selected method group", props<{ methodGroupItem: BrickMethodGroupModel }>());
// /** 选择玩法组大类 下的玩法 如 直选复式、直选单式、组选60等 */
// export const SetSelectedMethodDetial = createAction("[brick] set selected  method detail", props<{ methodDetail: LotteryMethodDetail }>());
/** 设置 奖金 */
export const SetBrickAdjustPrize = createAction("[brick] set brick prizes", props<{ adjustPrizes: { prizes: string, adjustModelString: string } }>());
// /** 设置 投注倒计时*/
// export const SetCountDownTimerArr = createAction("[brick] set count down timer arr", props<{ timerArr: string[][] }>());
/** 设置 投注注数、选号内容 */
export const SetBettingData = createAction("[brick] set betting-number and betting-content", props<{ bettingData: { bettingNum: number; betContent: any } }>());
/** 设置 投注注数、选号内容 */
export const SetBrickHeaderMini = createAction("[brick] set brick header mini display ", props<{ showMini: boolean }>());
/** 设置 撤单投注页面右侧和列表互通 */
export const SetCancelOrder = createAction("[brick] set brick cancel order booble ", props<{ cancelOrderState: boolean }>());
