
import { NgModule } from '@angular/core';
import { SubDigetPipe, } from './pipe/subDiget.pipe';
import { SubDigetTPipe } from './pipe/subDigetT.pipe';
import { FormatBirckNamePipe } from './pipe/format-brick-name.pipe';

@NgModule({
    declarations: [SubDigetPipe, SubDigetTPipe, FormatBirckNamePipe],
    exports: [
        SubDigetPipe,
        SubDigetTPipe,
        FormatBirckNamePipe,
    ]
})
export class PipeModule { }